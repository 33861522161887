import { RICHGO_API_ACCESS_TOKEN_KEY } from 'coreSrc/base/userSystemManager/user/authManager/const';
import documentCookie from './documentCookie';

const RnManager = {
  isUpdateAccessTokenDelay: false,
  getAccessToken() {
    return documentCookie.get(RICHGO_API_ACCESS_TOKEN_KEY);
  },
  query(type, otherValues?: any) {
    globalThis.webview?.postMessage(JSON.stringify({ type, ...otherValues }));
  },
  updateAccessToken() {
    try {
      const accessToken = this.getAccessToken();
      console.log('RnManager.updateAccessToken', accessToken);
      if (accessToken && !this.isUpdateAccessTokenDelay) {
        this.isUpdateAccessTokenDelay = true;
        setTimeout(() => {
          this.isUpdateAccessTokenDelay = false;
        }, 3000);
        this.query('updateAccessToken', { accessToken: this.getAccessToken() });
      }
    } catch (error) {
      console.error('exception occurred while updateting access token.', error);
    }
  },
  updateFCMToken() {
    this.query('updateFCMToken', { accessToken: this.getAccessToken() });
  },

  updateNewCookieAtReactNative(cookie: string) {
    // 최신 쿠키를 RN으로 보내주어 RN에서도 쿠키를 최신으로 관리한다.
    try {
      this.query('cookie', { content: cookie });
    } catch (error) {
      console.error('exception occurred while updating native app cookie.', error);
    }
  },
  updateNewLocalStorageAtReactNative(localStorage: string) {
    // 최신 로컬스토리지정보를 RN으로 보내주어 RN에서도 로컬스토리지를 최신으로 관리한다.
    try {
      this.query('localStorage', { content: localStorage });
    } catch (error) {
      console.error('exception occurred while updating native app localStorage.', error);
    }
  },
};

export default RnManager;
