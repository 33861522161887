import { useEffect } from 'react';

import useSystem from 'coreSrc/base/systemManager/useSystem';

export default function Device() {
  const system = useSystem();

  useEffect(() => {
    const type = getType();
    // alert(type);

    richgoAnalytics.log('device', { type });

    function getType() {
      const isApp = globalThis.ReactNativeWebView ? true : false;

      const typeInfos: string[] = [];

      /* 웹/앱 구분 */
      typeInfos.push(isApp ? 'app' : 'web');

      /* 디바이스 유형 : 데스크톱/태블릿/모바일 */
      typeInfos.push(system.detector.device);

      /* 운영체제 브랜드 */
      const osBrand = (() => {
        if (system.detector.os === 'android') return 'android';
        if (system.detector.os === 'iphone') return 'apple';
        if (system.detector.os === 'mac') return 'apple';
        if (system.detector.os === 'ipad') return 'apple';
        return 'etc';
      })();
      typeInfos.push(osBrand);

      return typeInfos.join('.');
    }
  }, []);

  return null;
}
