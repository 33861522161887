import {
  RICHGO_API_ACCESS_TOKEN_KEY,
  RICHGO_API_REFRESH_TOKEN_KEY,
} from 'coreSrc/base/userSystemManager/user/authManager/const';

import { RICHGO_API_HOST } from 'coreSrc/core/js/env';
import { parseCookie, setCookie } from 'coreSrc/core/js/utils/cookie';
import richFetch from 'coreSrc/core/js/utils/richFetch';

import ConstSellingAdmin from 'hostSrc/pages/realty/selling/helper/ConstSellingAdmin';

import type { TUserSystemActions } from '../useUserSystemReducer';
import UserProfile, { type BrokerApprovalState } from './UserProfile';

function useProfileReducer(profile: UserProfile, actions: TUserSystemActions) {
  const getId = () => profile.id;
  const getAgencyId = () => profile.agencyId;
  const getName = () => profile.name;
  const getEmail = () => profile.email;
  const getExternalEmail = () => profile.externalEmail;
  const getCommunityInfo = () => profile.community;
  const getMyHouseYn = () => profile.myHouseYn;
  const getMyParcelConditionYn = () => profile.myParcelConditionYn;
  const getHasLatestAgreedTerm = () => profile.hasLatestAgreedTerm;
  const getBrokerIsAgreed = () => profile.brokerIsAgreed;
  const getAgencyApprovalStateType = () => profile.agencyApprovalStateType;
  const getBrokerApprovalState = (): BrokerApprovalState => profile.brokerApprovalState;
  const getIsApprovalBroker = () =>
    profile.brokerApprovalState === ConstSellingAdmin.BrokerApprovalState.APPROVAL.code;
  const getFavoriteCount = () => profile.favoriteCount;
  const getIsKakaoPhoneAgree = () => profile.isKakaoPhoneAgree;
  const getExternalProviderName = () => profile.externalProviderName;

  async function setName(name: string) {
    await richFetch(`/user/nickname/${name}`, { method: 'PUT' });
    await actions.update();
  }

  async function updateLatestAgreeTerm() {
    await updateLatestAgreedTerm();
    await actions.update();
  }

  const getLivingArea = () => profile.livingArea;
  const setLivingBjd = async (bjdCode: string) => {
    const response = await richFetch(`/user/V2/region/${bjdCode}`, { method: 'PATCH' });
    if (response.status !== 200) throw response;
    await actions.update();
  };

  return {
    getId,

    getName,
    setName,

    getHasLatestAgreedTerm,
    updateLatestAgreeTerm,

    getLivingArea,
    setLivingBjd,

    getEmail,
    getExternalEmail,
    getCommunityInfo,
    getMyHouseYn,
    getMyParcelConditionYn,

    getBrokerIsAgreed,
    getIsApprovalBroker,
    getBrokerApprovalState,
    getAgencyApprovalStateType,
    getAgencyId,
    getFavoriteCount,
    getIsKakaoPhoneAgree,
    getExternalProviderName,
  };
}

export default useProfileReducer;

const updateLatestAgreedTerm = async () => {
  // /user/terms/R2Agree api 확인필요 -> 모든 필수항목들이 동의처리 되는건지??

  const response = await richFetch('/user/terms/R2Agree', { method: 'POST' });
  if (response.status !== 200) throw response;

  const refreshToken = parseCookie(document.cookie)[RICHGO_API_REFRESH_TOKEN_KEY];
  const headers = new Headers({ Authorization: `Bearer ${refreshToken}` });
  const accessTokenResponse = await fetch(`${RICHGO_API_HOST}/user/V2/access-token`, { headers });
  if (accessTokenResponse.status !== 200) throw accessTokenResponse;

  const accessTokenJson = await accessTokenResponse.json();
  const richgoApiAccessToken = accessTokenJson.result.accessToken;

  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DATE = 24 * HOUR;
  const YEAR = 365 * DATE;

  const accessTokenCookieEntries = [
    `${RICHGO_API_ACCESS_TOKEN_KEY}=${richgoApiAccessToken}`,
    'path=/',
    `max-age=${(2 * YEAR) / SECOND}`,
    'samesite=Lax',
  ];

  setCookie(accessTokenCookieEntries.join('; '));
};
