import richFetch from 'coreSrc/core/js/utils/richFetch';

import PARSER_PRICE from 'hostSrc/js/PARSER_PRICE';
import NOTIFICATION_CATEGORY_LIST from 'hostSrc/pages/realty/notification/notification-category-list';

import NotificationHelper from 'jhSrc/components/Notification/NotificationHelper';
import type { TNotificationSummary } from 'jhSrc/types/notification';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';

dayjs.extend(customParseFormat);

export interface ICreateNotificationDanjiSettingPayload {
  id: string;
  price: {
    meme: { active: boolean };
    jeonwolse: { active: boolean };
    pyeongTypeList: number[];
  };
  story: { active: boolean };
  goodnews: { active: boolean };
}

export const updateNotificationDanjiSetting = async (
  payload: ICreateNotificationDanjiSettingPayload
) => {
  const alarmNameList = [];

  if (payload.price.meme.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('meme').code);
  }

  if (payload.price.jeonwolse.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('jeonwolse').code);
  }

  if (payload.story.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('story').code);
  }

  if (payload.goodnews.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('goodnews').code);
  }

  const body = {
    targetId: payload.id,
    alarmNameList,
    pyeongTypeList: payload.price.pyeongTypeList ?? [],
  };
  const response = await richFetch('/user/alarm-danji', { method: 'POST', body });
  if (response.status !== 200) throw response;
};

export interface ICreateNotificationParcelSettingPayload {
  id: string;
  schedule: { active: boolean };
  story: { active: boolean };
  goodnews: { active: boolean };
}

export const updateNotificationParcelSetting = async (
  payload: ICreateNotificationParcelSettingPayload
) => {
  const alarmNameList = [];

  if (payload.schedule.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('schedule').code);
  }

  if (payload.story.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('story').code);
  }

  if (payload.goodnews.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('goodnews').code);
  }

  const body = {
    targetId: payload.id,
    favoriteCode: 'PARCEL',
    alarmNameList,
  };
  const response = await richFetch('/user/alarm', { method: 'POST', body });
  if (response.status !== 200) throw response;
};

interface IReadNotificationDanjiSettingPayload {
  id: string;
}

export const readNotificationDanjiSetting = async (payload: IReadNotificationDanjiSettingPayload) => {
  const response = await richFetch(`/user/alarm/DANJI/${payload.id}`);
  if (response.status !== 200) throw response;
  const json = await response.json();

  const price = {
    meme: { active: false },
    jeonwolse: { active: false },
    pyeongTypeList: (json.result.pyeongAlarmList ?? [])
      .filter((v) => v.alarmYn)
      .map((v) => v.pyeongType),
  };

  const data = {
    price,
    story: { active: false },
    goodnews: { active: false },
  };

  json.result.alarmDataList.forEach((d) => {
    if (d.alarmYn) {
      const topic = NotificationHelper.toDanjiSettingTopic(d.codeId);
      if (topic.id === 'meme') {
        price.meme.active = true;
      } else if (topic.id === 'jeonwolse') {
        price.jeonwolse.active = true;
      } else if (topic.id === 'goodnews') {
        data.goodnews.active = true;
      } else if (topic.id === 'story') {
        data.story.active = true;
      }
    }
  });

  return data;
};

interface IReadNotificationParcelSettingPayload {
  id: string;
}

export const readNotificationParcelSetting = async (
  payload: IReadNotificationParcelSettingPayload
) => {
  const response = await richFetch(`/user/alarm/PARCEL/${payload.id}`);
  if (response.status !== 200) throw response;
  const json = await response.json();

  const data = {
    schedule: { active: false },
    story: { active: false },
    goodnews: { active: false },
  };

  json.result.alarmDataList.forEach((d) => {
    if (d.alarmYn) {
      const topic = NotificationHelper.toParcelSettingTopic(d.codeId);
      if (topic.id === 'schedule') {
        data.schedule.active = true;
      } else if (topic.id === 'goodnews') {
        data.goodnews.active = true;
      } else if (topic.id === 'story') {
        data.story.active = true;
      }
    }
  });

  return data;
};

const CATEGORY = {
  real_transaction: 'real-trade',
};

interface IReadNotificationListPayload {
  deviceId?: string;
}

export const readNotificationList = async (payload: IReadNotificationListPayload) => {
  let urlSearchParams = [['deviceId', payload.deviceId]]
    .filter(([, value]) => (value ?? null) !== null)
    .map((entry) => entry.join('='))
    .join('&');
  urlSearchParams &&= '?' + urlSearchParams;

  const response = await richFetch('/user/notification/v2' + urlSearchParams);
  const json = await response.json();

  const lastViewdTime = new Date(json.result.last_date).getTime();
  json.result.articles?.map((v) => (v.isCustom = false));
  json.result.devices?.map((v) => (v.isCustom = true));
  json.result.users?.map((v) => (v.isCustom = true));

  json.result = [
    ...(json.result.articles ?? []),
    ...(json.result.devices ?? []),
    ...(json.result.users ?? []),
  ];

  json.result.sort((a, b) => {
    return new Date(b.updatedat).getTime() - new Date(a.updatedat).getTime();
  });

  const notificationList: TNotificationSummary[] = (json.result ?? [])
    .filter((v) => {
      const category = NOTIFICATION_CATEGORY_LIST.find((c) => c._id === v.category_id)?.id;

      switch (category) {
        case 'real-trade':
          return true;
        case 'richgo-richgoNow':
          return true;
        case 'richgo-richgoNowSpecificUser':
          return true;
        case 'custom-parcel':
          return true;
        case 'notice':
          return true;
        case 'weekly_hoga':
          return true;
        case 'sell_danji':
          return !!v.title;
        case 'update_history':
          return true;
        case 'radar':
          return true;
        case 'checklist':
          return true;
        case 'registry':
          return true;
        case 'auction':
          return true;
        default:
          return false;
      }
    })
    .map((v) => {
      const notification_category = NOTIFICATION_CATEGORY_LIST.find((c) => c._id === v.category_id);
      const category = notification_category?.id;
      const icon = notification_category?.icon;

      switch (category) {
        case 'checklist': {
          const summary = JSON.parse(v.summary);
          const description = createChecklistDescription(summary);
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            description,
            type: summary.type,
            danjiId: summary.recommand_danji_id ?? summary.danji_id,
            updatedAt: v.updatedat,
          };
        }

        case 'real-trade': {
          const summary = JSON.parse(v.summary);
          const data: {
            trade_type: string;
            pyeong_type: any;
            deposit: any;
            price: string | number;
            count: string;
            highest_price: number;
            yyyymmdd: string;
            last_price: number;
            last_yyyymmdd: string;
          }[] = summary.data;

          const description = _(
            data.map((d) => {
              let tradeType = d.trade_type ?? '-';

              if (tradeType === '전월세') {
                d.deposit ? (tradeType = '월세') : (tradeType = '전세');
              }
              return { tradeType, d };
            })
          )
            .groupBy((dd) => `${dd.tradeType} ${dd.d.pyeong_type ?? '-'}평`)
            .map((group, key) => {
              const groupDesc = group
                .map(({ tradeType, d }) => {
                  const money = parseInt(d.price ?? d.deposit);
                  const price =
                    tradeType === '월세'
                      ? `${PARSER_PRICE.getMoneyText(d.deposit, 2).format} / ${
                          PARSER_PRICE.getMoneyText(money, 2).format
                        }`
                      : `${PARSER_PRICE.getMoneyText(money, 2).format}`;

                  // count 및 highest_price는 신규 데이터에서 제거되나, 기존 데이터 호환성을 위해 예외처리 해서 남겨둠
                  const count = parseInt(d.count);
                  const additionalCount = count ? `외 ${count}건` : '';

                  let desc = ' • ';
                  desc += [price, additionalCount].filter((v) => (v ? true : false)).join(' ');

                  if (tradeType !== '월세' && d.highest_price) {
                    const diff = (+d.price / +d.highest_price - 1) * 100;
                    desc += ` (최고가 대비 ${Math.abs(diff).toFixed(1)}% ${diff >= 0 ? '▲' : '▼'})`;
                  }

                  // 여기서부터 신규 추가 데이터.
                  const contractDate = dayjs(d.yyyymmdd, 'YYYY-MM-DD', true);
                  desc += contractDate.isValid()
                    ? ` (계약일 ${contractDate.format('YY.MM.DD')})`
                    : '';

                  if (tradeType !== '월세' && d.last_price && d.last_yyyymmdd) {
                    const lastPrice = `${PARSER_PRICE.getMoneyText(d.last_price, 2).format}`;

                    const lastContractDate = dayjs(d.last_yyyymmdd, 'YYYY-MM-DD', true);
                    const lastContractDateStr = lastContractDate.isValid()
                      ? lastContractDate.format('YY.MM.DD')
                      : '-';

                    const diff = (+d.price / +d.last_price - 1) * 100;
                    desc += `\n    ※ 직전거래 대비 ${Math.abs(diff).toFixed(1)}% ${
                      diff >= 0 ? '▲' : '▼'
                    } (${lastPrice}, ${lastContractDateStr})`;
                  }

                  return desc;
                })
                .join('\n');

              return `${key}\n${groupDesc}`;
            })
            .sort()
            .join('\n\n');

          return {
            category,
            icon,
            realtyType: summary.realty_type,
            danji: { id: summary.danji_id ?? '' },
            title: `${v.title} 실거래가` ?? '',
            description,
            pyeong: summary.data[0].pyeong_type,
            updatedAt: v.updatedat,
          };
        }

        case 'richgo-richgoNow': {
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            updatedAt: v.updatedat,
            isCustom: v.isCustom,
          };
        }

        case 'richgo-richgoNowSpecificUser': {
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            updatedAt: v.updatedat,
            isCustom: v.isCustom,
          };
        }

        case 'update_history': {
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            updatedAt: v.updatedat,
          };
        }

        case 'custom-parcel': {
          const summary = JSON.parse(v.summary);

          const categoryLabel = `내조건청약 발표! 청약일 ${
            summary.parcel_start_date?.slice(5, 10).replace('-', '.') ?? ''
          }`;

          const title =
            summary.danji + ' ' + [summary.pyeong_type_min, summary.pyeong_type_max].join('~') + '평';

          const price = [summary.apply_max_price_min, summary.apply_max_price_max]
            .filter((v) => v)
            .map((v) => PARSER_PRICE.getMoneyText(v).format)
            .join('~');
          const relativePrice = (() => {
            if (summary.plus_price_by_around > 0)
              return (
                ' 시세대비' + PARSER_PRICE.getMoneyText(summary.plus_price_by_around).format + ' 저렴'
              );
            if (summary.plus_price_by_around < 0)
              return (
                ' 시세대비' +
                PARSER_PRICE.getMoneyText(Math.abs(summary.plus_price_by_around)).format +
                ' 비쌈'
              );
            return '';
          })();
          const description = '분양가 ' + price + ' /' + relativePrice;

          return {
            category,
            categoryLabel,
            icon,
            id: summary.danji_id ?? '',
            title,
            description,
            updatedAt: v.updatedat,
          };
        }

        case 'notice': {
          const summary: {
            danjiId: string;
            danjiName: string;
            data: string;
            type: number;
            url?: string;
            subcategory?: string;
          } = JSON.parse(v.summary);

          const noticeType = {
            1: 'opengoods',
            2: 'quickgoods',
            3: 'offer',
            4: 'error',
            5: 'raw',
            6: 'markdown',
          }[summary.type || 4];

          const mapper = {
            opengoods: {
              title: `${summary.danjiName} 주변 안팔린 매물량은?!`,
              description: `${summary.danjiName} 주변 안팔린 매물량이 얼마나 쌓이고 있을까요?`,
            },
            quickgoods: {
              title: `${summary.danjiName} 주변 급매물 확인하기`,
              description: '10%이상 저렴하게 나온 급매물을 확인하세요',
            },
            offer: {
              title: `${summary.danjiName} 주변 호가가 어떻게 변하고 있을까?`,
              description: `${summary.danjiName} 주변 호가 흐름을 확인하세요`,
            },
            error: {
              title: '이 동네 올라',
              description: '알림을 표시할 수 없습니다.',
            },
            raw: {
              title: v.title,
              description: summary.data,
            },
            markdown: {
              title: v.title,
              description: summary.data,
            },
          }[noticeType];

          return {
            category,
            icon,
            id: summary.danjiId ?? '',
            title: mapper.title,
            updatedAt: v.updatedat,
            description: mapper.description,
            noticeType: noticeType,
            danjiName: summary.danjiName,
            url: summary.url,
            subcategory: summary.subcategory,
          };
        }

        case 'weekly_hoga': {
          try {
            const summary = JSON.parse(v.summary);
            let description = '';

            summary.items.forEach((d, i, g) => {
              const tradeType = {
                jeonse: '전세',
                JEONSE: '전세',
                meme: '매매',
                MEME: '매매',
              }[d.type];

              const pyeongType = `${d.size ?? '-'}평`;

              const rateText = d.price_rate
                ? `(3개월 전 대비 ${d.price_rate > 0 ? '🔺 ' : '▼ '}${Math.abs(d.price_rate)}%)`
                : '';

              const money = parseInt(d.price ?? d.deposit);
              const price = `${PARSER_PRICE.getMoneyText(money).format}`;

              const count = parseInt(d.count);
              const additionalCount = count ? ` 외 ${count}건` : '';
              const ln = g.length - 1 !== i ? '\n' : '';
              description +=
                [tradeType, pyeongType, price, additionalCount, rateText]
                  .filter((v) => (v ? true : false))
                  .join(' ') + ln;
            });

            return {
              category,
              icon,
              title: `${v.title}`,
              realtyType: summary.realty_type,
              danji: { id: summary.danji_id ?? '' },
              pyeong: summary.items[0]?.size,
              description,
              updatedAt: v.updatedat,
            };
          } catch (err) {
            console.error(err, v);
            return {
              category,
              icon,
              title: '',
              danji: '',
              description: '',
              updateAt: v.updatedat,
            };
          }
        }
        case 'sell_danji': {
          const summary = JSON.parse(v.summary);
          if (summary.type === 'MARKET_SITUATION') {
            const nanCheck = [summary.diff_avg_price_3m_ago, summary.diff_min_price_3m_ago].includes(
              'nan'
            );

            const isDiffMinPriceOver0 = +summary.diff_min_price_3m_ago >= 0;
            const isDiffAvgPriceOver0 = +summary.diff_avg_price_3m_ago >= 0;

            const firstPriceStr = `\n최저호가 ${summary.min_price}억 ( 3개월 전 대비 ${
              isDiffMinPriceOver0 ? '▲' : '▼'
            } ${Math.abs(
              +summary.diff_min_price_3m_ago
            ).toLocaleString()}억 )\n평균호가 ${(+summary.avg_price).toLocaleString()}억 ( 3개월 전 대비 ${
              isDiffAvgPriceOver0 ? '▲' : '▼'
            } ${Math.abs(+summary.diff_avg_price_3m_ago).toLocaleString()}억 )`;

            // 첫문단
            const first = `\n📊 ${summary.str_wish_type} ${summary.pyeong}평 ${
              nanCheck ? '' : firstPriceStr
            }\n\n`;

            // 둘째문단
            const second = `🏘️ 내 아파트 가격(${(+summary.wish_price).toLocaleString()}억)\n이하로 나온 매물은 ${
              summary.min_opengoods_count
            }개\n더 비싸게 나온 매물은 ${(+summary.over_opengoods_count).toLocaleString()}개\n\n`;

            // 셋째문단
            const third = `🎈 현재 쌓여있는 매물수는 ${(+summary.opengoods_volume).toLocaleString()}개 (전월대비 ${
              summary.diff_opengoods_volume_1m_ago >= 0 ? '+' : ''
            }${summary.diff_opengoods_volume_1m_ago}개)\n\n`;

            // 넷째문단
            const fourth = `🏬 지난달 ${summary.sd} ${summary.sgg}\n${
              summary.str_wish_type
            } 거래 체결율 ${
              summary.transaction_opengoods_rate
            }%\n( 월간 거래량 ${(+summary.transaction_volume).toLocaleString()}건 / 현재 매물량 ${(+summary.region_opengoods_volume).toLocaleString()}건 )\n\n`;

            // 마지막
            const last =
              '등록하신 부동산 중개가 마무리 될 때까지 팔아줘 브리핑 및 중개인 대상 매물 노출은 계속 됩니다!\n';

            summary.summary = first + second + third + fourth + last;
            summary.addValue = summary.bjd_code;
          }

          if (summary.type === 'NOTI_REQUEST_BROKER') {
            summary.summary = `${summary.emd} ${summary.danji} ${summary.dong}동 ${summary.floor}층 매물의 고객 연락처를 지금부터 확인할 수 있습니다! 연락처를 확인해 중개 상담을 이어가 보세요`;
          }

          if (summary.type === 'NEW_REQUEST_UNOPEN') {
            summary.summary = '변경된 정책에 동의하고 빠르게 거래해 보세요!';
          }

          if (summary.type === 'NEW_FREE_TICKET') {
            summary.summary = `\n${summary.name} 중개사님! 3일간 사용할 수 있는 매물·매수자 찾기가 충전되었어요.\n\n● 만료일: ${summary.month}월 ${summary.day}일 (${summary.weekday}) AM00:00\n\n리치고에서 중개사님을 기다리는 매물과 매수자를 찾아보세요!`;
          }

          if (summary.type === 'EXPIRE_FREE_TICKET') {
            summary.summary = `\n${summary.name} 중개사님!  보유하신 무료 매물·매수자 찾기가 곧 만료될 예정입니다.\n\n● 만료일: ${summary.month}월 ${summary.day}일 (${summary.weekday}) AM00:00\n\n리치고에서 중개사님을 기다리는 매물과 매수자를 찾아보세요!`;
          }

          if (summary.type === 'NEW_SELL_GOODS_V2') {
            summary.summary = `\n중개사를 찾는 매물이 등록되었습니다. \n${summary.danji} ${summary.pyeong}평 ${summary.tradeType} ${summary.price}\n클릭하여 전화번호 보기`;

            summary.addValue = {
              sellGoodsId: summary.sellGoodsId,
              category: summary.category,
            };
          }

          return {
            category,
            icon,
            type: summary.type,
            title: v.title,
            description: summary.summary || summary.msg,
            updatedAt: v.updatedat,
            data: summary.addValue,
          };
        }

        case 'radar': {
          const summary = JSON.parse(v.summary);
          const desc = getRadarCardDesc(summary);
          return {
            category,
            icon,
            title: v.title,
            type: summary.type,
            danjiId: summary.danjiId,
            tradeType: summary.trade_type,
            description: desc || summary.text,
            updatedAt: v.updatedat,
          };
        }

        case 'registry': {
          const summary = JSON.parse(v.summary);

          const newData = {
            description: null,
            data: null,
          };

          /* NOTE: kunhee.lim
           *  registry는 현재 등기변동알림만 해당되는데 다른 타입이 추가되면
           *  sell_danji 처럼 분기쳐야함 */
          if (summary.type === 'DIFF_NOTIFICATION') {
            newData.description = createRegistryAlarmDescription(summary);
            newData.data = summary;
          }

          return {
            category,
            icon,
            id: v.row_id,
            type: newData.data?.type,
            title: v.title || '',
            description: newData.description,
            updatedAt: v.updatedat,
            data: newData.data,
          };
        }

        case 'auction': {
          const { type, data } = JSON.parse(v.summary);

          return {
            category,
            icon,
            id: v.row_id,
            title: v.title || '',
            type,
            data,
            updatedAt: v.updatedat,
          };
        }
      }

      return {
        category: CATEGORY[v.category_id],
        title: v.title ?? '',
        description: v.title ?? '',
      };
    });

  return {
    lastViewdTime,
    items: notificationList,
  };
};

export const readNotificationSettingList = async () => {
  const response = await richFetch('/api/user/user-push-list');
  const json = await response.json();

  const notificationSettingList = (json.result ?? [])
    .filter((setting) =>
      NOTIFICATION_CATEGORY_LIST.find((category) => category._id === setting.pushCategory)
        ? true
        : false
    )
    .map((setting) => {
      const category = NOTIFICATION_CATEGORY_LIST.find(
        (category) => category._id === setting.pushCategory
      );
      return {
        id: category.id,
        title: category.name,
        icon: category.icon,
        active: setting.isOn,
      };
    });

  return notificationSettingList;
};

interface IUpdateNotificationSettingListPayload {
  id: string;
  active: boolean;
}

export const updateNotificationSetting = async (payload: IUpdateNotificationSettingListPayload) => {
  const category = NOTIFICATION_CATEGORY_LIST.find((category) => category.id === payload.id);
  if (!category) return;

  const body = {
    pushCategory: category._id,
    isOn: payload.active,
  };

  const response = await richFetch('/api/user/user-push', {
    method: 'POST',
    body,
  });

  if (response.status !== 200) throw response;
};

function getRadarCardDesc(summary) {
  if (!summary.type) return '';

  if (summary.type === 'target') {
    return `${summary.trade_type === 'meme' ? '매매' : '전세'} ${summary.danjiName} ${
      summary.pyeong_type
    }평 ${PARSER_PRICE.getMoneyText(summary.price).format}`;
  }

  if (summary.type === 'around') {
    const temp = summary.data.map((item) => {
      // 레이더 주변단지 리스트
      const subText = item.target_data
        .map(
          (d) =>
            `  · ${d.emd} ${d.danji} ${d.pyeong_type}평 ${PARSER_PRICE.getMoneyText(d.price).format}`
        )
        .reduce((acc, cur) => `${acc}\n` + cur, '');

      const pyeongText =
        item.min_pyeong === item.max_pyeong
          ? `${item.min_pyeong}평`
          : `${item.min_pyeong}~${item.max_pyeong}평`;

      // 레이더 타겟 단지
      return `🏡 ${item.radar_danji} 인근 ${pyeongText} ${item.trade_type_str} ${
        PARSER_PRICE.getMoneyText(item.radar_price).format
      } 이하${subText}\n`;
    });

    return temp.reduce((acc, cur) => `${acc}\n` + `${cur}`, '');
  }

  return;
}

function createChecklistDescription(summary: any) {
  if (summary.type1) {
    return `\n최근 확인하신 ${summary.danji}의 지표는\n매우 유의 ${summary.type1}, 유의 ${summary.type2}, 보통 ${summary.type3}, 좋음 ${summary.type4}, 매우 좋음 ${summary.type5}\n${summary.sd} ${summary.sgg}에서는 ${summary.recommand_danji}도 주목해보세요!`;
  } else if (summary.first_up_indicator_id || summary.down_up_indicator_id) {
    const name = summary.danji;

    const upMain = summary.first_up_indicator_id;
    const upCount = summary.up_count;
    const downMain = summary.down_up_indicator_id;
    const downCount = summary.down_count;

    if (!downMain) {
      return `${name} ${upMain}${upCount > 1 ? `외 ${upCount - 1}가지 ` : ''} 점수가 올라갔어요.`;
    } else if (!upMain) {
      return `${name} ${downMain}${upCount > 1 ? `외 ${downCount - 1}가지 ` : ''} 점수가 내려갔어요.`;
    } else {
      return [
        `${name} 지표를 확인해보세요!`,
        upCount > 1
          ? `${upMain} 외 ${upCount - 1}가지 점수가 올라갔어요.`
          : `${upMain} 점수가 올라갔어요.`,
        downCount > 1
          ? `${downMain} 외 ${downCount - 1}가지 점수가 내려갔어요.`
          : `${downMain} 점수가 내려갔어요.`,
      ].join('\n');
    }
  } else {
    return '';
  }
}

function createRegistryAlarmDescription(summary: {
  address: string;
  change: string;
  propertyKey: string;
  registrationNumber: string;
  state: string;
  type: string;
}) {
  return (
    [summary.address, summary.change, summary.state]
      .filter(Boolean)
      .map((item) => `- ${item}`)
      .join('\n') || ''
  );
}
