export default class LinkHelper {
  static openExternal(url: string, target?: 'browser') {
    if (globalThis.richgoWebview) {
      if (target === 'browser') {
        // android 47버전 출시 후 예외처리 코드 삭제
        if (navigator.userAgent.toLowerCase().indexOf('android') !== -1)
          return window.open(url, '_blank');
        return globalThis.richgoWebview.postMessage({ type: 'open-browser', href: url });
      }
      return globalThis.richgoWebview.postMessage({ type: 'external', value: { url } });
    }
    return window.open(url, '_blank');
  }
}
