import type { AppContext } from 'next/app';

import type { IFetchRichgoApiOptions } from './createRichFetch';

import uuid from 'react-uuid';

import { RICHGO_API_HOST } from 'coreSrc/core/js/env';

import ContextRichgoAuthHelper from 'hostSrc/pages/_app/middleware/ContextRichgoAuthHelper';
import ContextCookieHelper from 'hostSrc/pages/_app/middleware/ContextCookieHelper';

import { parseCookie, setCookie } from '../cookie';

interface FetchRichgoApiMiddlewareContext {
  url: string;
  options: IFetchRichgoApiOptions;
  appContext?: AppContext;
}

export default async function useRequestMiddleware(context: FetchRichgoApiMiddlewareContext) {
  if (context.url.startsWith(RICHGO_API_HOST)) {
    await useBearerToken(context);
    useGuid(context);
  }

  useJsonContent(context.options);
}

/** middleware */
async function useBearerToken(context: FetchRichgoApiMiddlewareContext) {
  const headers = context.options.headers ?? {};
  if ('authorization' in headers || 'Authorization' in headers) {
    return;
  }

  const richgoApiAccessToken = ContextRichgoAuthHelper.getAccessToken(context.appContext);
  if (richgoApiAccessToken) {
    const bearerToken = `Bearer ${richgoApiAccessToken}`;
    context.options.headers ??= {};
    context.options.headers.authorization ??= bearerToken;
  }
}

/** middleware */
function useJsonContent(options: IFetchRichgoApiOptions) {
  const hasBody = options.body ? true : false;
  const hasContentTypeInHeader = options.headers?.['Content-Type'] ? true : false;

  if (hasBody) {
    if (hasContentTypeInHeader === false) {
      options.headers ??= {};
      options.headers['Content-Type'] = 'application/json';
      options.body &&= JSON.stringify(options.body);
    }
  }
}

function useGuid(context?: FetchRichgoApiMiddlewareContext) {
  const isClient = globalThis.window ? true : false;
  const cookie = isClient
    ? parseCookie(document.cookie)
    : parseCookie(context.appContext?.ctx?.req?.headers?.cookie);

  let guid = cookie.guid;

  if (!guid) {
    const newGuid = uuid();

    if (isClient) {
      setCookie(`guid=${newGuid}`);
    } else {
      if (context?.appContext) {
        ContextCookieHelper.push(context.appContext, [`guid=${newGuid}; path=/`]);
      }
    }
    guid = newGuid;
  }

  if (isClient) {
    if (document.referrer.includes('https://invest.zum.com')) {
      guid = 'zum';
    }
  }

  context.options.headers ??= {};
  context.options.headers.Guid = guid;
}
