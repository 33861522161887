import {
  deleteUserFavoriteAuctionAuctionId,
  postUserFavoriteDanjiSummary,
  putUserFavoriteAuctionAuctionId,
} from 'hostSrc/apis/endpoints/user/user';
import type { TUserSystemActions } from '../useUserSystemReducer';

import UserFavorites from './UserFavorites';
import { FavoriteDanjiInputDto } from 'hostSrc/apis/model';

function useFavoritesReducer(favorites: UserFavorites, actions: TUserSystemActions) {
  // const hasSetting = (id: string) =>
  //
  // favorites.setting.list.find((setting) => setting.id === id) ? true : false;

  // const getSettingList = () => notification.setting.list;

  // const getDanjiSettingList = () =>
  //   notification.setting.list.filter(
  //     (setting) => setting.type === 'danji'
  //   ) as TUserNotificationDanjiSetting[];

  const getAllFavoritesList = () => {
    return favorites;
  };

  const setFavoriteOfficetel = async (payload: FavoriteDanjiInputDto) => {
    await postUserFavoriteDanjiSummary(payload);
  };

  const getIsFavoriteOfficetel = (id: string) => {
    return favorites.officetelList.find((officetel) => officetel.id === id) ? true : false;
  };

  const setFavoriteAuction = async (auctionId: string) => {
    await putUserFavoriteAuctionAuctionId(auctionId);
    await actions.update();
  };

  const deleteFavoriteAuction = async (auctionId: string) => {
    await deleteUserFavoriteAuctionAuctionId(auctionId);
    await actions.update();
  };

  const getIsFavoriteAuction = (id: string) => {
    return favorites.auctionList.find((auction) => auction.id === id) ? true : false;
  };

  return {
    getAllFavoritesList,

    // 오피
    setFavoriteOfficetel,
    getIsFavoriteOfficetel,

    // 경매
    setFavoriteAuction,
    deleteFavoriteAuction,
    getIsFavoriteAuction,
  };
}

export default useFavoritesReducer;
