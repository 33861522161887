import { useMemo } from 'react';

import { NextPageContext } from 'next';
import { useRouter } from 'next/router';

import { useIsomorphicLayoutEffect } from 'coreSrc/core/js/hooks';

const BEFORE_PATH_NAME_SYMBOL = Symbol('before-pathname');

export const SEO = {
  origin: 'https://m.richgo.ai',
  title: '리치고 - 부동산 투자 분석 No.1',
  description: '리치고에서 아파트, 토지, 건물의 실거래가와 경매! 호재와 학군, 미래 시세까지',
  keywords: [
    '리치고',
    '데이터노우즈',
    '부동산',
    '빅데이터',
    'AI',
    '인공지능',
    '아파트 실거래가',
    '아파트 시세',
    '오피스텔 실거래가',
    '오피스텔 시세',
    '재건축',
    '재개발',
    '청약',
    '교통',
    '가장 빠른 국토교통부 실거래가 업데이트',
    '실거래가',
    '시세',
    '호가',
    '매매',
    '전세',
    '월세',
    '토지',
    '건물',
    '경매',
    '상업용부동산',
    '권리분석',
    '법원경매',
    '토지시세',
    '건물시세',
    'AI추정가',
    '아파트별PIR',
    '투자체크',
    '부동산앱',
    '올인원',
    '부동산 통합앱',
  ],

  siteName: '리치고',
  src: 'https://m.richgo.ai/opengraph.png',
};

export interface ISeoMeta {
  url: string;
  title: string;
  description: string;
}

export default function useSeo(seo?: ISeoMeta) {
  const router = useRouter();

  const seoMetaMemo = useMemo(() => {
    let siteName = '리치고';
    let imageSrc = 'https://m.richgo.ai/opengraph.png';
    let description = seo?.description ?? SEO.description;
    if (router.pathname === '/signal') {
      const idx = +router.query.startIdx || 0;
      imageSrc = `https://m.richgo.ai/signal/share${idx}.png`;
      switch (idx) {
        case 0:
          siteName = '리치고 - 우리 동네 올라?';
          description = '우리 동네 가격, 매물량, 거래량은?';
          break;
        case 1:
          siteName = '리치고 - 부동산 계급도';
          description = '우리 동네는 상급지일까?';
          break;
        case 2:
          siteName = '리치고 - 전문가 전망은?';
          description = '전문가들의 부동산 가격 예측';
          break;
      }
    }
    return {
      url: seo?.url ?? SEO.origin + router.asPath.replace(/[?].+$/, ''),
      title: seo?.title ?? SEO.title,
      description,
      keywords: SEO.keywords.join(', '),
      siteName,
      imageSrc,
    };
  }, [new URL(router.asPath, SEO.origin).pathname]);

  const pathname = new URL(router.asPath, 'https://dummy.com').pathname;
  useIsomorphicLayoutEffect(() => {
    globalThis[BEFORE_PATH_NAME_SYMBOL] = pathname;
  }, [pathname]);

  return seoMetaMemo;
}

function getPreviusPathname() {
  return globalThis[BEFORE_PATH_NAME_SYMBOL] ?? '';
}

export function isSeoUpdatable(ctx: NextPageContext) {
  const previusPathname = getPreviusPathname();
  const currentPathname = new URL(ctx.asPath, SEO.origin).pathname;
  return ctx.req || previusPathname !== currentPathname;
}
