import { useEffect, useMemo } from 'react';

import { useRouter as useNextRouter } from 'next/router';

import { getUniqueId } from 'coreSrc/core/js/draft';

import LinkHelper from 'jhSrc/utils/LinkHelper';

import PopupStack from './PopupStack';
import getPath from './getPath';
import getQueryString from './getQueryString';
import getRoutes from './getRoutes';
import {
  RouteCloseOptions,
  RouteData,
  RouteKey,
  RouteOptions,
  RouteParams,
  RouterGoData,
} from './types';

type PopupCloseOptions = Omit<RouteCloseOptions, 'step'>;

const SEPARATOR = '+';

// NOTE: kangseongofdk
// - 이 값들을 `useRouteReducer` 안에 state 로 녹일 수 있는 방법이 있을까?
// - 그냥 넣기만 하면 버그 발생
//  next/router 의 초기화 시기와 데이터 셋팅 시기가 엇갈리면서 에러가 터짐
// - 어차피 싱글톤인데, 굳이 state 로 넣어야 하나 싶기도 하고..
let _onPopupChange;
const _state = {
  historyIdxBeforePopup: null,
  stopCallback: false,
};
const _routes = getRoutes();
const _popupState = new PopupStack();
const initialHistoryLength = (globalThis?.window?.history?.length ?? 2) - 1; // 초기 히스토리 길이를 저장

function getPopups(url?: string) {
  const hashString = (url || location.hash).match(/#(.+)$/)?.[1];
  return !hashString ? [] : hashString.split(SEPARATOR).map((hash) => _popupState.findById(hash));
}

function useRouteReducer() {
  const router = useNextRouter();
  useEffect(() => {
    let t0;
    if (getPopups().length > 0) {
      t0 = setTimeout(() => {
        router.replace(`${location.pathname}${location.search}`);
      }, 0);
    }
    return () => {
      clearTimeout(t0);
    };
  }, []);

  useEffect(() => {
    const hashChangeStart = (url) => {
      if (_state.stopCallback) {
        return;
      }

      /* NOTE: kunhee.lim
       *  1. history.state.idx 값이 없음 (언제부터 고장난지 모름)
       *  2. history.state.idx 에 직접 값을 관리해도 무슨 이유인지 history.state.idx 가 사라질 때가 있다 (undefined)
       *  ㄴ> 위 이유들로 globalThis.historyIdx 에 히스토리를 관리 하도록 처리했으나 더 좋은 방법이 있을지 고민 해보자 */
      const historyLength = history.length - initialHistoryLength;
      globalThis.historyIdx = historyLength < 1 ? 1 : historyLength;

      const popups = getPopups(url);
      if (popups.length === 0) {
        _state.historyIdxBeforePopup = null;
      } else {
        _state.historyIdxBeforePopup = globalThis.historyIdx;
      }

      return _onPopupChange?.(popups);
    };
    const routeChangeStart = hashChangeStart;

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('hashChangeStart', hashChangeStart);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('hashChangeStart', hashChangeStart);
    };
  }, [_onPopupChange]);

  const routerQuery = useMemo<Record<string, string>>(() => {
    if (!router.query || Object.keys(router.query).length === 0) {
      return {};
    }

    return Object.entries(router.query).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: isNaN(+value) ? value : +value,
      };
    }, {});
  }, [router.query]);

  const goToPopup = (route: any, data: any, options: any = {}) => {
    const { replace, containerOptions, params, onlyOne } = options;
    const popups = getPopups();

    if (popups.length === 0) {
      _state.historyIdxBeforePopup = globalThis.historyIdx;
    }

    const newItem = {
      id: `${route.key}${getUniqueId()}`,
      key: route.key,
      data,
      containerOptions,
    };
    _popupState.push(newItem);

    // const lastItemIsDialog = popups[popups.length - 1]?.key === 'dialog';
    const needReplace = onlyOne && popups.length;
    if (onlyOne) {
      popups.splice(0, popups.length);
    } else if (replace /* || lastItemIsDialog*/) {
      popups.pop();
    }
    popups.push(newItem);

    // FIXME: kangseongofdk
    const queryString = getQueryString({ ...params }, { keepOriginal: true });
    const newPath = `${location.pathname}${queryString}#${popups.map((it) => it.id).join(SEPARATOR)}`;
    if (replace /* || lastItemIsDialog*/ || needReplace) {
      router.replace(newPath);
    } else {
      router.push(newPath);
    }
  };

  const goWithUrl = (url: string, { replace }: any = {}) => {
    if (replace) {
      router.replace(url);
    } else {
      router.push(url);
    }
  };

  const goToPage = (route: any, query?: any, { replace }: any = {}) => {
    // const popups = getPopups();
    // const lastItemIsDialog = popups[popups.length - 1]?.key === 'dialog';

    _popupState.push(null);

    const newPath = getPath(route, query);
    if (replace /* || lastItemIsDialog*/) {
      router.replace(newPath);
    } else {
      router.push(newPath);
    }
  };

  const goTo = (key: RouteKey, data: any = {}, options: any = {}) => {
    const route = _routes[key];
    if (!route) {
      goToPage({ key });
      return;
    }
    switch (route.type) {
      case 'popup':
      case 'dialog': {
        const { containerOptions, params, popupOptions, ...dataWithoutOptions } = data;
        goToPopup(route, dataWithoutOptions, {
          containerOptions,
          params,
          ...popupOptions,
          ...options,
        });
        break;
      }
      default:
        goToPage(route, data, options);
        break;
    }
  };

  const updateSearchParams = (params, replace, swallow) => {
    // FIXME: kangseongofdk
    const queryString = getQueryString({ ...router.query, ...params });
    const newPath = `${router.pathname}${queryString}${location.hash}`;

    if (replace) {
      router.replace(newPath, undefined, { shallow: swallow });
    } else {
      router.push(newPath, undefined, { shallow: swallow });
    }
  };

  return {
    get pathname() {
      return router.pathname;
    },
    get query() {
      return routerQuery;
    },
    asPath: router.asPath,
    isFallback: router.isFallback,
    basePath: router.basePath,
    locale: router.locale,
    locales: router.locales,
    defaultLocale: router.defaultLocale,
    domainLocales: router.domainLocales,
    isReady: router.isReady,
    isPreview: router.isPreview,
    setOnPopupChangeListener(listener) {
      _onPopupChange = listener;
    },
    go<Key extends RouteKey>(key: Key, data?: RouterGoData<Key>, options?: RouteOptions) {
      goTo(key, data, options);
    },
    goOut(href: string, newWindow: boolean | { target: 'browser' } = false) {
      if (newWindow) {
        const target = typeof newWindow === 'boolean' ? undefined : 'browser';
        LinkHelper.openExternal(href, target);
      } else {
        window.location.href = href;
      }
    },
    // TODO: kangseongofdk
    // - (prevParams) => void
    setParams(params: RouteParams, { replace, swallow = false }: RouteOptions = {}) {
      updateSearchParams(params, replace, swallow);
    },
    replace(key: RouteKey, data?: RouteData) {
      goTo(key, data, { replace: true });
    },
    // TODO: kangseongofdk
    // - 히스토리가 없을 때는 지정된 "홈"으로 이동
    back({ thenGo, step, onDone }: RouteCloseOptions & { onDone?: () => void } = {}) {
      console.log('router', router, this);
      if (thenGo) {
        goTo(thenGo[0], thenGo[1], { replace: true });
        return;
      }
      if (step) {
        window.history.go(step * -1);
        return;
      }

      if (onDone) {
        const onPopstate = () => {
          window.removeEventListener('popstate', onPopstate);
          onDone();
        };
        window.addEventListener('popstate', onPopstate);
      }

      if (globalThis.historyIdx <= 1 && getPopups().length === 0) {
        // this.replace('index');
        goTo('index');
        return;
      }

      router.back();
    },
    backTo({ url /*, key */ }: { url: string /*, key: RouteKey*/ }) {
      if (!url) {
        return;
      }

      const onPopstate = () => {
        if (history.state.url === url) {
          window.removeEventListener('popstate', onPopstate);
        } else {
          router.back();
        }
      };
      window.addEventListener('popstate', onPopstate);
      router.back();
    },
    nowOn(key: RouteKey) {
      return router.pathname === _routes[key].path;
    },
    closePopup({ thenGo }: PopupCloseOptions = {}) {
      if (_state.historyIdxBeforePopup === null) {
        return;
      }

      if (thenGo) {
        _state.stopCallback = true;
        const onPopstate = () => {
          window.removeEventListener('popstate', onPopstate);
          // _state.historyIdxBeforePopup = null;
          _state.stopCallback = false;
          goTo(thenGo[0], thenGo[1]);
        };
        window.addEventListener('popstate', onPopstate);
      }

      router.back();
    },
    closeAllPopups({ thenGo }: PopupCloseOptions = {}) {
      if (_state.historyIdxBeforePopup === null) {
        return;
      }
      if (globalThis.historyIdx === _state.historyIdxBeforePopup && thenGo) {
        goTo(thenGo[0], thenGo[1], { replace: true });
        return;
      }

      _state.stopCallback = true;

      const onPopstate = () => {
        if (globalThis.historyIdx <= _state.historyIdxBeforePopup) {
          window.removeEventListener('popstate', onPopstate);
          // _state.historyIdxBeforePopup = null;
          _state.stopCallback = false;
          if (thenGo) {
            goTo(thenGo[0], thenGo[1], { replace: true });
          } else {
            router.back();
          }
        } else {
          router.back();
        }
      };

      window.removeEventListener('popstate', onPopstate);
      window.addEventListener('popstate', onPopstate);

      router.back();
    },
    /**
     * @주의 - url그대로 라우팅이 필요할때만 사용할 것
     * - (ex : 알림 상세 -> 링크 이동)
     */
    push(url: string, options?: RouteOptions) {
      goWithUrl(url, options);
    },
  };
}

export default useRouteReducer;
